import { useDispatch } from "react-redux"
import { Outlet } from "react-router-dom"
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useEffect } from "react"
import { getUserByToken } from "../modules/auth/redux/AuthCRUD"

const ForceAuth: React.FC = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        var path = window.location.pathname.replace("/", "").split("/")
        var url = window.location.origin

        const getToken = async () => {
            const { data: user } = await getUserByToken(path[2] as string)
            dispatch(auth.actions.fulfillUser(user))
        }

        const fetchData = async () => {
            await getToken()
            dispatch(auth.actions.login(path[2]))
            window.location.href = url + "/dashboard"
        }

        fetchData();
    }, [])

    return (
        <Outlet />
    )
}

export { ForceAuth }